'use client';
import { Button } from '@heroui/button';
import { FaGg } from '@react-icons/all-files/fa/FaGg';
import { FaShip } from '@react-icons/all-files/fa/FaShip';
import { FiArrowUpRight } from '@react-icons/all-files/fi/FiArrowUpRight';
import { HiOutlineLocationMarker } from '@react-icons/all-files/hi/HiOutlineLocationMarker';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { ReactNode, useState } from 'react';

import { Skeleton } from '@heroui/skeleton';

const LoadingTab = () => {
    return (
        <div className='p-2 bg-white'>
            <Skeleton className='rounded-lg'>
                <div className='h-12 rounded-lg bg-default-300' />
            </Skeleton>
        </div>
    );
};

import RatesSearch from './RatesSearch';
// import SchedulesSearch from './SchedulesSearch';
// import TrackingSearch from './TrackingSearch';
// const RatesSearch = dynamic(() => import('./RatesSearch'), {
//     ssr: false,
//     loading: LoadingTab,
// });
const SchedulesSearch = dynamic(() => import('./SchedulesSearch'), {
    ssr: false,
    loading: LoadingTab,
});
const TrackingSearch = dynamic(() => import('./TrackingSearch'), {
    ssr: false,
    loading: LoadingTab,
});

type Props = {};

enum TabEnums {
    RATES = 'RATES',
    TRACKING = 'TRACKING',
    SCHEDULES = 'SCHEDULES',
}

const QuotationsTabs = (props: Props) => {
    const t = useTranslations('home');
    const [activeTab, setActiveTab] = useState<TabEnums>(TabEnums.RATES);

    const tabs: Array<{
        Id: TabEnums;
        Name: string;
        StartIcon: ReactNode;
        EndIcon?: ReactNode;
        Color: 'primary' | 'success' | 'warning' | 'secondary';
        MobileHidden: boolean;
        IsDisabled?: boolean;
    }> = [
        {
            Id: TabEnums.RATES,
            Name: t('rates'),
            StartIcon: <FaShip className='hidden sm:block text-primary' />,
            Color: 'primary',
            MobileHidden: false,
        },
        {
            Id: TabEnums.TRACKING,
            Name: t('tracking'),
            StartIcon: <HiOutlineLocationMarker className='hidden sm:block text-success' />,
            Color: 'success',
            MobileHidden: false,
        },
        // {
        //     Id: TabEnums.SCHEDULES,
        //     Name: t('schedules'),
        //     StartIcon: <FaCalendar className='hidden sm:block text-warning' />,
        //     Color: 'warning',
        //     MobileHidden: false,
        //     IsDisabled: true,
        // },
    ];
    return (
        <div className='flex flex-col gap-1'>
            <div className='flex gap-2'>
                <div className='flex gap-2 p-1 rounded-xl w-full md:w-fit drop-shadow-1 bg-white mb-1'>
                    {tabs?.map((tab) => {
                        return (
                            <Button
                                size='sm'
                                variant={tab.Id === activeTab ? 'flat' : 'light'}
                                color={tab.Color}
                                className={`!font-bold uppercase !text-xs !text-gray-700 flex-1 md:flex-auto ${
                                    tab.MobileHidden && 'hidden md:flex'
                                }`}
                                onClick={() => {
                                    setActiveTab(tab.Id);
                                }}
                                key={tab.Id}
                                startContent={tab.StartIcon}
                                endContent={tab?.EndIcon}
                                isDisabled={tab?.IsDisabled}
                            >
                                {tab.Name}
                            </Button>
                        );
                    })}
                </div>

                <div className='hidden md:flex gap-2 p-1 rounded-xl w-full md:w-fit drop-shadow-1 bg-white mb-1'>
                    <Button
                        as={Link}
                        href='/shipping-request'
                        size='sm'
                        variant={'light'}
                        color={'secondary'}
                        className={`!font-bold uppercase !text-xs !text-gray-700 flex-1 md:flex-auto`}
                        startContent={<FaGg className='text-secondary' />}
                        endContent={<FiArrowUpRight size={18} strokeWidth={2.4} />}
                    >
                        {t('requestAQuote')}
                    </Button>
                </div>
            </div>
            <div>
                {activeTab === TabEnums.RATES && <RatesSearch isHomePage />}
                {activeTab === TabEnums.TRACKING && <TrackingSearch />}
                {activeTab === TabEnums.SCHEDULES && <SchedulesSearch isHomePage />}
            </div>
        </div>
    );
};

export default QuotationsTabs;
